<script>
    import "../app.postcss";

    import { dev } from '$app/environment';
    import { inject } from '@vercel/analytics';
    
    inject({ mode: dev ? 'development' : 'production' });
</script>
  
<slot />
  
<svelte:head>
    <link rel="stylesheet" href="/global.css" />
    <meta name="description" content="Harness the power of generative AI with SFIA GPT—a fine-tuned GPT model designed to conduct skill assessments—starting with the SFIA framework." />
    <meta name="description" property="og:description" content="Harness the power of generative AI with SFIA GPT—a fine-tuned GPT model designed to conduct skill assessments—starting with the SFIA framework." />
    <meta name="keywords" content="SFIA, GPT, AI, skill, assessment, interview, chatbot, generative, language, model" />
    <meta name="keywords" property="og:keywords" content="SFIA, GPT, AI, skill, assessment, interview, chatbot, generative, language, model" />
    <meta name="image" content="/who-is-sfiagpt.jpg" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="/who-is-sfiagpt.jpg" />
    <meta name="author" content="Ooj" />
    <meta name="author" property="og:author" content="Ooj" />
    <title>SFIA GPT</title>
</svelte:head>
